<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
                <titlu-pagina Titlu="Inventar" @on_add_clicked="show_dialog()" :AdaugaVisible="true"/>
            </div>
          </template>
          <div class="filtre">
            <el-form @submit.prevent.native="refresh_info()" label-position="top" size="mini">
              <el-row :gutter="20" style="flex-direction:row">
                <el-col style="width:auto">
                  <el-form-item label="Gestiuni">
                    <el-select class="full-width" v-model="Filters.IdGestiune" filterable>
                      <el-option v-for="item in Info.Gestiuni" :key="item.Id" :label="item.Nume" :value="item.Id" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col style="width:auto">
                  <el-form-item class="full-width" label="Interval">
                    <el-date-picker class="full-width" v-model="Filters.DataInventar" type="daterange"
                      range-separator="-" start-placeholder="Data inceput" end-placeholder="Data sfarsit"
                      :picker-options="datePickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col style="width:auto">
                  <el-form-item label="Stare">
                    <el-select class="full-width" v-model="Filters.Stare">
                      <el-option :value="-1" :label="'Toate'"></el-option>
                      <el-option :value="0" :label="'Activ'"></el-option>
                      <el-option :value="1" :label="'Anulat'"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col>
                <div class="footer-form">
                  <el-button size="mini" type="primary" native-type="submit" @click="refresh_info()">Aplica
                  </el-button>
                  <el-button size="mini" type="danger" @click="reset()">Reseteaza</el-button>
                  <el-button style="float:right;" size="mini" type="info" @click="dialogFormVisible = true">Listeaza fise</el-button>
                </div>
              </el-col>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" :data="Inventare" class="tabelRandMic" size="mini">
      <el-table-column prop="Id" label="#" width="100" />
      <el-table-column prop="NrDoc" label="Nr Doc" width="100" />
      <el-table-column label="Data document">
        <template slot-scope="scope">
          {{ scope.row.Data | Data }}
        </template>
      </el-table-column>
      <el-table-column prop="NumeGestiune" label="Gestiune" />
      <el-table-column label="Anulat">
        <template slot-scope="scope">
          {{ scope.row.Anulat == 0 ? "Nu" : "Da" }}
        </template>
      </el-table-column>
      <el-table-column
        align="right" 
        fixed="right"
        label="Actiuni"
        width="150px"
      >
        <template slot-scope="scope">
          <el-tooltip content="Modificare">
            <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="show_dialog(scope.row.Id)"  class="butonReg"/>
          </el-tooltip>
          <el-tooltip content="Anuleaza">
            <el-button type="danger" size="mini" icon="el-icon-close" circle @click="anuleaza(scope.row.Id)"  class="butonReg"/>
          </el-tooltip>
          <el-tooltip content="Print">
            <el-button type="info" size="mini" icon="el-icon-printer" circle @click="print(scope.row.Id)" class="butonReg"/>
          </el-tooltip>
          <el-tooltip content="Descarca PDF">
            <el-button type="success" size="mini" icon="el-icon-download" circle @click="downloadPdf(scope.row.Id,scope.row.NrDoc)"  class="butonReg" />
          </el-tooltip>
        </template>
      </el-table-column>
      </el-table>

    <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />

    <Inventar_dialog ref="dlg" @save="refresh_info()" ></Inventar_dialog>
    <el-dialog :visible.sync="dialogFormVisible" title="Liste inventariere pe gestiune" append-to-body>
      <el-form :model="form">
        <el-form-item>
          <el-select size="mini" v-model="form.IdGestiune" placeholder="Alege gestiunea">
            <el-option v-for="item in Info.Gestiuni" :key="item.Id" :label="item.Nume" :value="item.Id" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="dialogFormVisible = false">Anuleaza</el-button>
          <el-button size="mini" :disabled="form.IdGestiune == ''" type="primary" @click="listeaza()">Printeaza</el-button>
        </span>
      </template>
    </el-dialog>
    <div>
      <VueHtml2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1500"
          filename="Inventar"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a2"
          pdf-orientation="portrait"
          pdf-content-width="1600px"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <div v-html="fetchedHTML"></div>
          </section>
        </VueHtml2pdf>
    </div>
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from "@/widgets/TitluPagina";
import Inventar_dialog from "@/pages/inventar/Inventar_dialog.vue";
import { jsPDF } from "jspdf";
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from "html2pdf.js";


export default {
  name: 'Inventar',
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "titlu-pagina": TitluPagina,
    "Inventar_dialog": Inventar_dialog,
    VueHtml2pdf
  },
  data() {
    return {
      dialogFormVisible: false,
      Inventare: [],
      fetchedHTML:'',
      form: {
        IdGestiune: '',
      },
      Filters: {
        IdGestiune: '',
        NumeGestiune: '',
        DataInventar: [],
        Stare: -1,
      },
      Info: {
        Gestiuni: [],
      },
      datePickerOptions: { firstDayOfWeek: 1 },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    }
  },
  methods: {
    async get_info() {
      var response = await this.post('Inventar/get_info');
      this.Info.Gestiuni = response.gestiuni;
      this.Inventare = response.inventare;
      this.refresh_info();
    },
    async refresh_info() {
      var response = await this.post('Inventar/index', {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Inventare = response.Results;
      this.PaginationInfo = response.PaginationInfo;
    },
    reset() {
      this.Filters = {
        IdGestiune: '',
        NumeGestiune: '',
        DataInventar: [],
      }
      this.refresh_info();
    },
    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
    anuleaza(Id) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          var response = await this.post("inventar/anuleaza_inventar", { id: Id });
          this.$message({
            type: "info",
            message: response.message,
          });
          this.refresh_info();
        })
        .catch(() => {
          this.$message({ type: "info", message: response.message });
        });
    },
    async print(idInventar) {
      let res = await this.post('inventar/print', { Id: idInventar });

      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    }, 

    async downloadPdf(id,numar){
      let res = await this.post('inventar/print', { Id: id });
      // this.fetchedHTML = res.html;
      const blankWindow = window.open('', '_blank');
      // await this.$refs.html2Pdf.generatePdf();
      html2pdf(res.html, {
				margin: 0,
  			filename: 'Inventar',
        html2canvas: { scale: 3 },
			}).then(() => {
        blankWindow.close();
      });
      // blankWindow.close();

      // const toDownloadDocument = new jsPDF()

      // const blankWindow = window.open('', '_blank');

      // toDownloadDocument.html(res.html, {
      //   callback: () => {
      //     toDownloadDocument.save('Inventar_'+numar)
      //     blankWindow.close();
      //   },
      //   x: 15,
      //   y: 15,
      //   width: 180,
      //   windowWidth: 1200,
      // })
    },
    
    async listeaza() {
      let res = await this.post('inventar/liste_inventar', { idGestiune: this.form.IdGestiune });
      // var url = base_url + "/liste_inventar" + this.form.IdGestiun;
      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },
    tableRowClassName(anulat) {
      if(anulat == 1)
        return 'table-danger'
    }
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  }
}
</script>

<style lang="less" scoped>
.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-input-number {
    width: 100% !important;
  }

  .tabel-data {
    width: 300px !important;
    word-break: keep-all;
  }
}

.table{
  margin: 25px 10px;
  padding: 30px;
}
</style>